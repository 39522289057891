table .message img {
    width: 25px;
    height: 25px;
}

table .status {
    white-space: nowrap;
}

.animate {
    animation: slide-up 1s ease;
    transition: 1s;
}

@keyframes slide-up {
    0% {
        opacity: 0;
        max-height: 0;
    }
    100% {
        opacity: 1;
        max-height: 100%;
    }
}
